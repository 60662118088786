// 使用方式 this.$store.dispatch('setItem',{key:value})
// 使用方式 this.$store.commit('setItem',{key:value})
const modules = {
  common: {
    state() {
      return {
        isConnect: true,
        userInfo: {}, // 用户信息
        adminInfo: {},
        salesInfo: {},// 销售信息
        signInfo: {},// 签到信息
        userId: 0,
        userReportInfo: {}//报名信息
      }
    },
    mutations: {
      setItem(state, data) {
        for (let key in data) {
          state[key] = data[key];
        }
      }
    },
    actions: {
      setItem(context, data) {
        context.commit("setItem", data)
      }
    }
  }
}

export default modules;